import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"

export default makeStyles((theme: Theme) =>
  createStyles({
    main: {
      maxWidth: 960,
      width: "100%",
      padding: `0 1.0875rem`,
      margin: "1.5rem auto 0"
    },
    menuItem: {
      "&:hover": {
        color: theme.palette.primary.main
      },
      fontSize: "1.1rem",
      borderBottom: "none",
      marginLeft: theme.spacing(3),
      transition: "all .5s ease",
      textTransform: "capitalize"
    },
    active: {
      color: theme.palette.primary.main
    },
    centerDiv: {
      flexGrow: 1
    },
    toolbar: {
      padding: "0"
    },
    titleContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    title: {
      marginRight: theme.spacing(1),
      [theme.breakpoints.down(630)]: {
        marginBottom: "1rem"
      }
    },
    tabletUp: {
      transition: "all .5s ease",
      [theme.breakpoints.down(630)]: {
        display: "none"
      }
    },
    mobile: {
      transition: "all .5s ease",
      display: "none",
      padding: ".7rem 0",
      [theme.breakpoints.down(630)]: {
        display: "block"
      }
    },
    fabContainer: {
      display: "flex",
      justifyContent: "center",
      margin: ".2rem 0"
    },
    fab: {
      transition: "all .5s ease",
      margin: "0 .7rem",
      color: theme.palette.neutral.main,
      [theme.breakpoints.down(450)]: {
        margin: "0 .5rem"
      }
    }
  })
)
