import React from "react"
import { Link, navigate } from "gatsby"
import clsx from "clsx"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import Tooltip from "@material-ui/core/Tooltip"
import { Fab } from "@material-ui/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faHome,
  faBriefcase,
  faClipboardList,
  faAddressBook
} from "@fortawesome/free-solid-svg-icons"

import useStyles from "../styles/header"

const paths = ["/", "/projects", "/skills", "/contact"]
const name = ["home", "projects", "skills", "contact"]
const pathIcons = [faHome, faBriefcase, faClipboardList, faAddressBook]

const Header: React.FC<Props> = props => {
  const c = useStyles()
  const active = (path: string) => {
    if (path === props.uri) return c.active
    return ""
  }

  return (
    <main id="back-to-top-anchor" className={c.main}>
      <Toolbar className={clsx(c.toolbar, c.tabletUp)}>
        <Typography className={c.title} color="primary" variant="h5">
          {props.siteTitle}
        </Typography>
        <div className={c.centerDiv} />
        {paths.map((p, i) => (
          <Link key={p} to={p} className={clsx(c.menuItem, active(p))}>
            {name[i]}
          </Link>
        ))}
      </Toolbar>
      <Toolbar className={clsx(c.toolbar, c.mobile)}>
        <div className={c.titleContainer}>
          <Typography className={c.title} color="secondary" variant="h5">
            {props.siteTitle}
          </Typography>
        </div>
        <div className={c.fabContainer}>
          {pathIcons.map((p, i) => (
            <Tooltip title={name[i]} key={i}>
              <Fab
                className={c.fab}
                color="primary"
                size="small"
                aria-label={paths[i]}
                onClick={() => navigate(paths[i])}
              >
                <FontAwesomeIcon icon={p} />
              </Fab>
            </Tooltip>
          ))}
        </div>
      </Toolbar>
    </main>
  )
}

interface Props {
  siteTitle: string
  uri: string
}

Header.defaultProps = {
  siteTitle: ``
}

export default Header
