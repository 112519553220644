import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ScrollTop from "../components/ScrollToTop"
import { Fab } from "@material-ui/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretUp } from "@fortawesome/free-solid-svg-icons"
import useStyles from "../styles/layout"

import Header from "./header"

const Layout: React.FC<Props> = props => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const { title } = data.site.siteMetadata
  const c = useStyles()

  return (
    <main>
      <Header uri={props.uri} siteTitle={title} />
      <section className={c.section}>
        <div>{props.children}</div>
        <ScrollTop {...props}>
          <Fab color="secondary" size="small" aria-label="scroll back to top">
            <FontAwesomeIcon icon={faCaretUp} />
          </Fab>
        </ScrollTop>
      </section>
    </main>
  )
}

interface Props {
  uri: string
}

export default Layout
